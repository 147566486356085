import React from "react";
import MediaQuery from "react-responsive";

const scheduleItems = [
    {
        name: "第1回",
        startTime: new Date('2024-08-26T12:00:00'),
        endTime: new Date('2024-09-23T08:59:59'),
    },
    {
        name: "第2回",
        startTime: new Date('2024-09-24T11:59:59'),
        endTime: new Date('2024-10-20T08:59:59'),
    },
    {
        name: "第3回",
        startTime: new Date('2024-10-21T11:59:59'),
        endTime: new Date('2024-11-23T08:59:59'),
    },
    {
        name: "第4回",
        startTime: new Date('2024-11-24T11:59:59'),
        endTime: new Date('2024-12-15T08:59:59'),
    },
    {
        name: "第5回",
        startTime: new Date('2024-12-15T11:59:59'),
        endTime: new Date('2024-01-19T08:59:59'),
    }
    // {
    //     name: "第6回",
    //     startTime: new Date('2025-01-19T11:59:59'),
    //     endTime: new Date('2025-01-28T08:59:59'),
    // }
];

const getCurrentSchedule = () => {
    const now = new Date();
    return scheduleItems.find(item => now <= item.endTime);
};

const formatMonthDay = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const weekday = date.toLocaleString("default", { weekday: "short" });
    return `${month}${day}日(${weekday})`;
};

const Public = () => {
    const currentSchedule = getCurrentSchedule();
    
    // Check if currentSchedule is not undefined
    if (currentSchedule) {
        const { startTime, endTime } = currentSchedule;
        const now = new Date();
        if (now < startTime) {
            return <h1 className="font-weight-bold">第１回　予約開始:8/26～（12:00～）開始</h1>;
            // return <h1 className="font-weight-bold">準備中です</h1>;
        }
        
        return (
            <h1 className="font-weight-bold">
                {formatMonthDay(endTime)} <h4 className="mt-1">予約受付中</h4>
            </h1>
        );
    }

    // Return a default message if no current schedule is found
    return <h1 className="font-weight-bold">第１回　予約開始:8/26～（12:00～）開始</h1>;
};

const PublicSp = () => {
    const currentSchedule = getCurrentSchedule();

    // Check if currentSchedule is not undefined
    if (currentSchedule) {
        const { startTime, endTime } = currentSchedule;
        const now = new Date();
        if (now < startTime) {
            return <h1 className="font-weight-bold">第１回　予約開始:8/26～（12:00～）開始</h1>;
            // return <h1 className="font-weight-bold">準備中です</h1>;
        }
        return (
            <h4 className="font-weight-bold">
                {formatMonthDay(endTime)} <h6 className="mt-1">予約受付中</h6>
            </h4>
        );
    }

    // Return a default message if no current schedule is found
    return <h4 className="font-weight-bold">第１回　予約開始:8/26～（12:00～）開始</h4>;
};

const PublicDeadline = () => {
    return (
        <>
            <MediaQuery query="(max-width: 768px)">
                <PublicSp />
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
                <Public />
            </MediaQuery>
        </>
    );
};

export default PublicDeadline;